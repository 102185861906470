import React from "react"
import { Container, Col, Row } from "reactstrap"
import styled from "styled-components"
import { FaExclamationCircle } from "react-icons/fa"
let StyledFeature = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;
  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }
  margin-bottom: 1em;
  background: ${(props) =>
    props.important ? props.theme.primary : "transparent"};
  color: ${(props) => (props.important ? "white" : "inherit")};
  a {
    background: ${(props) => (props.important ? "white" : "inherit")};
  }
`

let Feature = ({ title, important, children }) => (
  <StyledFeature important={important}>
    <Row>
      <Col md={12} className="mb-12">
        <div className="p-3">
          <h5>
            <FaExclamationCircle /> {title}
          </h5>
          {children}
        </div>
      </Col>
    </Row>
  </StyledFeature>
)

let HomeNews = () => {
  return (
    <Container className="py-5">
        <Feature title="Wegen Urlaub 30.12.2024 - 06.01.2025">
        <p>
          Nächste Ordination am <strong>07.01.2024</strong> ab 07:30 Uhr. Vertretung hat:
          <ul><li>Dr. Polak - Nestelbach - 03133/2244</li></ul>
        </p>
      </Feature>
      <Feature title="Neue Testmethoden" important={true}>
        <ul>
          <li>Corona-Virus Diagnostik</li>
          <li>Antigen-Schnellstest (Ergebnis in 20 Minuten)</li>
          <li>
            PCR-Test-Abnahme{" "}
            <strong>nach telefonischer Voranmeldung möglich</strong>
          </li>
          <li>Antikörper Schnelltest</li>
        </ul>
      </Feature>
    </Container>
  )
}

export default HomeNews
